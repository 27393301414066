import moment from "moment/moment";
function capitalizeText(text) {
    return text?.toLowerCase()
        .split(' ')
        .map(word => word.charAt(0)?.toUpperCase() + word.slice(1))
        .join(' ');
}
export const getQueryString = (data) => {
    const keyData = Object.keys(data)
    var paramData = ""
    keyData.map((value, index) => {
        if (index == 0) {
            paramData = `${paramData}${value}=${data[value]}`
        } else {
            paramData = `${paramData}&${value}=${data[value]}`
        }
    })
    return paramData
}
export const documentNames = (name) => {
    switch (name) {
        case "aadhar_card":
            return "Aadhar Card"
        case "drive_license":
            return "Drive License"
        case "pancard":
            return "Pan Card"
        case "voter_id":
            return "Voter ID"
        case "resume":
            return "Resume"
        default:
            return capitalizeText(name?.split("_")?.join(" "))
    }
}
export const showFileIcon = (documentFile, name) => {
    return documentFile.includes(name) ? "show__file__data__opacity" : "file__data__opacity"
}
export const pvcColor = (scr) => {
    if (scr) {
        let score = Number(scr * 100)
        if (score >= 90) {
            return "#008a33"
        } else if (score >= 80) {
            return "#8ed43a"
        } else if (score >= 60) {
            return "#bed900"
        } else {
            return "#e86523"
        }
    }
}
function client_age_format(date) {
    date = new Date(date * 1000);
    const now = moment();
    const targetDate = moment(date, "DD-MMM-YYYY hh:mm:ss A");
    const diff = now.diff(targetDate, 'minutes');
    var formated_age;
    if (diff < 1) {
        formated_age = "just now";
    } else if (diff < 60) {
        formated_age = diff + (diff === 1 ? " min" : " mins") + " ago";
    } else if (diff < 1440) {
        const hours = Math.floor(diff / 60);
        const minutes = diff % 60;
        formated_age = hours + (hours === 1 ? " hr" : " hrs") + " " + minutes + (minutes === 1 ? " min" : " mins") + " ago";
    } else if (diff < 10080) {
        const days = Math.floor(diff / 1440);
        const hours = Math.floor((diff % 1440) / 60);
        const minutes = diff % 60;
        formated_age = days + (days === 1 ? " day" : " days") + " " + hours + (hours === 1 ? " hr" : " hrs") + " " + minutes + (minutes === 1 ? " min" : " mins") + " ago";
    } else if (diff < 40320) {
        const weeks = Math.floor(diff / 10080);
        const days = Math.floor((diff % 10080) / 1440);
        const hours = Math.floor((diff % 1440) / 60);
        const minutes = diff % 60;
        formated_age = weeks + (weeks === 1 ? " week" : " weeks") + " " + days + (days === 1 ? " day" : " days") + " " + hours + (hours === 1 ? " hr" : " hrs") + " " + minutes + (minutes === 1 ? " min" : " mins") + " ago";
    } else {
        formated_age = targetDate.format("DD-MMM-YYYY hh:mm:ss A");
    }
    return formated_age;
}
export { capitalizeText, client_age_format }

export function formatDateTime(dateTimeString) {
const dateObj = new Date(dateTimeString);

// Extract date components
const year = dateObj.getFullYear();
const month = String(dateObj.getMonth() + 1).padStart(2, '0');
const day = String(dateObj.getDate()).padStart(2, '0');

// Extract time components
let hours = dateObj.getHours();
const minutes = String(dateObj.getMinutes()).padStart(2, '0');
// const seconds = String(dateObj.getSeconds()).padStart(2, '0');

// Determine AM/PM and convert to 12-hour format
const ampm = hours >= 12 ? 'PM' : 'AM';
hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
hours = String(hours).padStart(2, '0');

// Combine into desired format
return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
}
  
  
  